import React from 'react'
import { useTranslation } from 'react-i18next'

import ProvinceHeader from 'components/ProvinceHeader'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import ChangesList from 'components/ChangesList'

const Alberta = () => {
  const { t } = useTranslation()

  return (
    <>
      <MetaTags title={`${t('provinces.ab')} | ${t('title')}`} />
      <ProvinceHeader name={t('provinces.ab')} slug="alberta" />
      <Section>
        <Wrapper>
          <ChangesList
            name={t('provinces.ab')}
            changes={t('changes.ab', { returnObjects: true })}
          />
        </Wrapper>
      </Section>
    </>
  )
}

export default Alberta
